<template>
  <PrimaryMenu />

  <Header title="Kapcsolat" />

  <SectionBlock>
    <SectionTitle title="Vegye fel velünk a kapcsolatot az alábbi elérhetőségeinken" />
    <SectionContent>
      <ContactInfo vertical />
    </SectionContent>
  </SectionBlock>

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContent from "@/components/layout/sections/parts/SectionContent";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";
import ContactInfo from "@/components/layout/sections/parts/contact/ContactInfo";

export default {
  name: "About",
  title: "Magunkról",
  components: {
    ContactInfo,
    PrimaryMenu,
    Header,
    SectionBlock,
    SectionTitle,
    SectionContent,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
